import { LabelProps, Label as ReactStrapLabel } from "reactstrap";

export const Label = (
  props: LabelProps & {
    children: React.ReactNode;
    required?: boolean;
  }
) => {
  const { required, children, ...otherProps } = props;
  return (
    <ReactStrapLabel {...otherProps}>
      {children}
      {required && <span style={{ color: "red" }}>*</span>}
    </ReactStrapLabel>
  );
};
