import styled from "styled-components";

export const ErrorText = styled.div`
  color: #f02849;
  text-align: left;
`;

export const SuccessText = styled.div`
  color: #43c9ba;
  text-align: left;
`;

export const WarningText = styled.div`
  color: #e9741b;
  text-align: left;
`;
