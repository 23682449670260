import { ITranslation } from "./types";

export const de: ITranslation = {
  messageFallbacks: {
    homeOrderNotReadyMsg:
      'Vielen Dank für deinen Einkauf! Dein Kassenbon ist noch in Bearbeitung, bitte komme in einige Zeit wieder zurück! <br/>Bei Fragen oder Anregungen kontaktiere uns bittte über die Service Mail <a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>',
    errorReachOutSupportMsg:
      'In case of any issues, reach out for support at <a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>',
  },
  api: {
    E_FORCED_LOGOUT:
      "Sie wurden abgemeldet, da Ihre Verbindung abgelaufen ist.",
  },
  orderStatus: {
    draft: "Entwurf",
    contested: "Reklamiert",
    paid: "Bezahlt",
    unpaid: "Unbezahlt",
    pending: "Ausstehend",
    reviewed: "Überprüft",
    paymentProcessing: "Zahlungsabwicklung",
    refunded: "Rückerstattet",
    unknown: "Unbekannt",
  },
  receiptsList: {
    headings: {
      date: "Datum",
      time: "Zeit",
      status: "Status",
      total: "Total",
    },
    action: {
      view: "Anschauen",
    },
    statusTooltip: {
      draft: "Entwurf - Kassenbon wird zurzeit überarbeitet durch das System",
      paid: "Bezahlt - Kassenbon ist erstellt und bezahlt",
      paymentProcessing:
        "Zahlungsabwicklung - Kassenbon ist erstellt und Zahlungsvorgang gestartet",
      unpaid:
        "Unbezahlt - Kassenbon ist erstellt aber Zahlung ist leider nicht erfolgreich abgeschlossen ",
      pending:
        "Pending - Kassenbon würde reklamiert durch Kunde und is derzeit in Bearbeitung bei der Retailer",
      reviewed:
        "Reviewed - Kassenbon würde reklamiert durch Kunde und Retailer hat diese überprüft",
    },
  },
  receiptDetailScreen: {
    headerTitle: "Beleg Details",
    subtotal: "Nettosumme:",
    taxBreakdown: {
      headers: {
        rate: "Steuer %",
        netto: "Netto",
        brutto: "Brutto",
        tax: "Steuer",
        total: "Gesamtbetrag:",
      },
    },
    tax: "Steuer",
    taxCRV: "CRV",
    total: "Summe:",
    processTime: "Bearbeitungszeit",
    date: "Datum",
    listOfProducts: "Artikelauflistung:",
    paymentStatus: "Zahlungsstatus:",
    orderId: "Bon-Nummer:",
  },
  receiptDetailComplain: {
    reportProblem: "Problem melden",
    submitReview: "Zur Überprüfung einsenden",
    confirmTitle: "Beleg gesendet",
    confirmButton: "Fertig",
    addMissingItem: "Produkt hinzufügen",
    addMissingItemMessage: "Eine Nachricht hinzufügen (optional)",
    form: {
      email: {
        label: 'Wie können wir Sie kontaktieren?',
        placeholder: 'Ihre E-Mail, z. B. me@example.com',
        errors: {
          required: 'E-Mail-Adresse ist erforderlich.',
          isEmail: 'Ungültige E-Mail-Adresse.',
          inUse: 'E-Mail-Adresse wird bereits verwendet.'
        }
      }
    }
  },
  receiptsScreen: {
    label: {
      contested: "in Überprüfung",
      draft: "Entwurf",
      empty: "Leer",
      paid: "Bezahlt",
      pending: "Ausstehend",
      refunded: "Rückerstattet",
      reviewed: "Überarbeitet",
      unknown: "Unbekannt",
      unpaid: "Unbezahlt",
      paymentProcessing: "Zahlungsabwicklung",
    },
    message: {
      contested: "in Überprüfung Nachricht",
      draft: "Entwurf der Nachricht",
      empty: "Leere Nachricht",
      paid: "Bezahlte Nachricht",
      pending: "Ausstehende Nachricht",
      refunded: "Rückerstattungsnachricht",
      reviewed: "Überprüfte Nachricht",
      unpaid: "Unbezahlte Nachricht",
      paymentProcessing: "Zahlungsabwicklung",
    },
    minutes: "Minuten",
    hours: "Std",
    days: "Tage",
  },
  slideModal: {
    close: "Schließen",
    searchPlaceholder: "Artikel suchen",
  },
  receiptsHome: {
    receiptPortal: "Kassenbon Portaal",
    selectPaymentType:
      "Wählen Sie die bevorzugte Methode, um Ihre Quittung zu finden",
    cardBin:
      "Die ersten %{cardBinDigitsCount} Ziffern der Kreditkartennummer (BIN/IIN)",
    last4: "Die letzten 4 Ziffern der Kreditkartennummer",
    last4Apple: "Die letzten 4 Ziffern Ihre Apple Pay Nummer",
    last4Google: "Die letzten 4 Ziffern Ihre Google Pay Nummer",
    instructionApple:
      "Wo find ich die letzten 4 Ziffern von meinem Apple Pay Card Nummer in meine Apple Wallet",
    openWallet: "Apple Wallet öffnen",
    instructionGoogle:
      "Wo find ich die letzten 4 Ziffern von meinem Google Pay Card Nummer in meine Google Wallet",
    instructionExternalId:
      "Finden Sie die Zahlungsreferenznummer in Ihrer Zahlungs-App oder Ihrem Kontoauszug.",
    externalIdLabel: "Zahlungsreferenznummer",
    externalIdPlaceholder: "",
    expDate: "Ablaufdatum der Kreditkarte",
    visitDate: "Datum Ihres Besuchs",
    submit: "Quittungen zeigen",
    registerEmail:
      "Geben Sie bitte Ihre E-mail um zukünftige Kassenbon zu bekommen",
    registerEmailSuccess: "E-mail Adresse ist erfolgreich registriert.",
    registerEmailFail:
      "E-mail Adress ist nicht registriert weil es leider keine Kassenbon verknüpft ist mit die eingegebene Kartennummer und/oder Einkaufsdatum.",
    unrecognizedError: "An unrecognized error occurred",
    inconclusiveCardInfo: "We can not find your receipt",
    invalidCaptcha: "Invalid captcha, please try again",
    oasisApiError:
      "We could not connect to the receipts server. Please try again later.",
  },
};
