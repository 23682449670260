import { ITranslation } from "./types";

export const nl: ITranslation = {
  messageFallbacks: {
    homeOrderNotReadyMsg:
      'Net de winkel uit? Het kan even duren voordat je bon is verwerkt en zichtbaar wordt in het portaal.<br/>Ondervind je problemen? Neem dan contact op met de klantenservice, via <a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>',
    errorReachOutSupportMsg:
      'In case of any issues, reach out for support at <a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>',
  },
  api: {
    E_FORCED_LOGOUT: "U bent uitgelogd omdat de connectie is verlopen.",
  },
  orderStatus: {
    draft: "Concept",
    contested: "Betwist",
    paid: "Betaald",
    unpaid: "Onbetaald",
    pending: "In behandeling",
    reviewed: "Beoordeeld",
    paymentProcessing: "Betaling wordt verwerkt",
    refunded: "Teruggegeven",
    unknown: "Onbekend",
  },
  receiptsList: {
    headings: {
      date: "Datum",
      time: "Tijd",
      status: "Status",
      total: "Totaal",
    },
    action: {
      view: "Bekijk",
    },
    statusTooltip: {
      draft: "Concept - Order wordt momenteel gegenereerd door het systeem",
      paid: "Betaald - Order is gegenereerd en betaald",
      paymentProcessing:
        "Betalingsverwerking - Order is gegenereerd en betalingsverwerking gestart",
      unpaid: "Onbetaald - Order is gegenereerd maar betaling is niet gelukt",
      pending:
        "In behandeling - Order is betwist door de klant en is in behandeling bij de retailer",
      reviewed:
        "Reviewed - Order is betwist door de klant en de retailer heeft deze behandeld",
    },
  },
  receiptDetailScreen: {
    headerTitle: "Kassabon gegevens",
    subtotal: "Subtotaal",
    taxBreakdown: {
      headers: {
        rate: "BTW %",
        netto: "Netto",
        brutto: "Bruto",
        tax: "BTW",
        total: "Totaal:",
      },
    },
    tax: "BTW",
    taxCRV: "CRV",
    total: "Totaal",
    processTime: "Procestijd",
    date: "Datum",
    paymentStatus: "Betaalstatus",
    orderId: "Order ID",
    listOfProducts: "Producten",
  },
  receiptDetailComplain: {
    reportProblem: "Rapporteer een probleem met deze kassabon",
    submitReview: "Stuur voor een review",
    confirmTitle: "Kassabon-melding verstuurd",
    confirmButton: "Klaar",
    addMissingItem: "Voeg missende item toe",
    addMissingItemMessage: "Voeg boodschap toe (optioneel)",
    form: {
      email: {
        label: 'Hoe kunnen we contact met u opnemen?',
        placeholder: 'Uw e-mail, bijv. me@example.com',
        errors: {
          required: 'E-mailadres is verplicht.',
          isEmail: 'Ongeldig e-mailadres.',
          inUse: 'E-mailadres is al in gebruik.'
        }
      }
    }
  },
  receiptsScreen: {
    label: {
      contested: "IN BEHANDELING",
      draft: "CONCEPT",
      empty: "LEEG",
      paid: "BETAALD",
      pending: "DRAFT",
      refunded: "TERUGGESTORT",
      reviewed: "HERZIEN",
      unknown: "ONBEKEND",
      unpaid: "ONBETAALD",
      paymentProcessing: "BETALINGSVERWERKING",
    },
    message: {
      contested: "Betwiste kassabon",
      draft: "Concept kassabon",
      empty: "Lege kassabon",
      paid: "Betaalde kassabon",
      pending: "In bewerking kassabon",
      refunded: "Teruggestorte kassabon",
      reviewed: "Behandelde kassabon",
      unpaid: "Onbetaalde kassabon",
      paymentProcessing: "Betaling wordt verwerkt",
    },
    minutes: "minuten",
    hours: "uren",
    days: "dagen",
  },
  slideModal: {
    close: "Sluit",
    searchPlaceholder: "Zoek item",
  },
  receiptsHome: {
    receiptPortal: "Kassabonnen Portaal",
    selectPaymentType:
      "Kies de gewenste methode om uw ontvangstbewijs te vinden",
    cardBin:
      "Eerste %{cardBinDigitsCount} cijfers van een kaartnummer (BIN/IIN)",
    last4: "Geef de laatste 4 cijfers van uw betaalkaart",
    last4Apple: "Geef de laatste 4 cijfers van uw Apple Pay rekeningnummer",
    last4Google: "Geef de laatste 4 cijfers van uw Google Pay rekeningnummer",
    instructionApple:
      "Vind de laatste 4 cijfers van uw Apple Pay Card in uw Apple Wallet",
    openWallet: "Open uw Apple Wallet",
    instructionGoogle:
      "Vind de laatste 4 cijfers van uw Google Pay Card  in uw Wallet",
    instructionExternalId:
      "Zoek het betalingsreferentienummer in uw betalingsapp of bankafschrift.",
    externalIdLabel: "Betalingsreferentienummer",
    externalIdPlaceholder: "",
    expDate: "Geldig tot",
    visitDate: "Datum van uw bezoek",
    submit: "Zoek Kassabon(nen)",
    registerEmail: "Geef uw e-mail om uw kassabonnen te ontvangen",
    registerEmailSuccess: "Email adres succesvol opgeslagen.",
    registerEmailFail:
      "Email adres niet opgeslagen omdat er geen kassabonnen aanwezig zijn met deze betaalkaart en/of datum van bezoek.",
    unrecognizedError: "An unrecognized error occurred",
    inconclusiveCardInfo: "We can not find your receipt",
    invalidCaptcha: "Invalid captcha, please try again",
    oasisApiError:
      "We could not connect to the receipts server. Please try again later.",
  },
};
